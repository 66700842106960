<template>
    <div class="row table-sm mr-0 ml-0 p-0">
        <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">

            <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2">
                <h5 class="d-inline mr-2 font-weight-bold">{{$t('message.floor')}}</h5>
                <crm-refresh @c-click="refresh()"></crm-refresh>
                <div class="text-center d-flex sorddata ml-3">
                    <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
                        v-model="filterForm.search" clearable></el-input>
                </div>
            </div>

            <div class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset">
                <el-button v-can="'floors.create'" size="mini" @click="drawer.create.status = true"
                    icon="el-icon-circle-plus-outline">
                    {{$t('message.create')}}
                </el-button>
            </div>

        </div>
        <table class="table table-bordered table-hover" v-loading="loadingData">
            <crm-pagination :pagination="pagination" @c-change="updatePagination"/>
            <thead>
                <tr>
                    <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"/>
                    <crm-th :column="columns.corps_id" :sort="sort" @c-change="updateSort"/>
                    <crm-th :column="columns.floor" :sort="sort" @c-change="updateSort"/>
                    <crm-th :column="columns.settings" :sort="sort" @c-change="updateSort"/>
                </tr>

                <tr>
                    <th v-if="columns.id.show">
                        <el-input clearable size="mini" v-model="filterForm.id" class="id_input"
                            :placeholder="columns.id.title"/>
                    </th>
                    <th v-if="columns.corps_id.show">
                        <el-select filterable clearable :placeholder="$t('message.corps')" size="mini"
                            v-model="filterForm.corps_id">
                            <el-option v-for="item in corpses" :key="item.name + item.id" :label="item.name"
                                :value="item.id"/>
                        </el-select>
                    </th>
                    <th v-if="columns.floor.show">
                        <el-input clearable size="mini" v-model="filterForm.name" :placeholder="$t('message.floor')"/>
                    </th>

                    <th v-if="columns.settings.show"></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="user in list" :key="user.id" class="cursor-pointer">
                    <td v-if="columns.id.show">{{ user.id }}</td>
                    <td v-if="columns.floor.show">{{ user.corps.name }}</td>
                    <td v-if="columns.floor.show">{{ user.name }}</td>
                    <td v-if="columns.settings.show" class="settings-td">
                        <crm-setting-dropdown :model="user" name="floors" :actions="actions" @edit="edit"
                            @delete="destroy">
                        </crm-setting-dropdown>
                    </td>
                </tr>
            </transition-group>
        </table>
        <el-drawer size="60%" :wrapperClosable="false" :visible.sync="drawer.create.status" :ref="drawer.create.name"
            @opened="drawerOpened(drawer.create.component)" @closed="drawerClosed(drawer.create.component)">
            <crm-create :ref="drawer.create.component" :drawer-name="drawer.create.name"/>
        </el-drawer>

        <el-drawer size="60%" :wrapperClosable="false" :visible.sync="drawer.update.status" :ref="drawer.update.name"
            @opened="drawerOpened(drawer.update.component)" @closed="drawerClosed(drawer.update.component)">
            <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name"/>
        </el-drawer>

    </div>

</template>

<script>
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import CrmCreate from "./components/crm-create_floor";
    import CrmUpdate from "./components/crm-update_floor";
    import list from "@/utils/mixins/list";
    import drawer from "@/utils/mixins/drawer";
    export default {
        mixins: [list, drawer],
        name: "floor",
        components: {
            CrmCreate,
            CrmUpdate
        },
        data() {
            return {
                loadingData: false,
                selectedModel: {},
                drawer: {
                    create: {
                        name: "create",
                        status: false,
                        component: 'componentDrawerCreate'
                    },
                    update: {
                        name: "update",
                        status: false,
                        component: 'componentDrawerUpdate'
                    }
                }
            }
        },
        computed: {
            ...mapGetters({
                list: 'floors/list',
                columns: "floors/columns",
                pagination: "floors/pagination",
                filter: "floors/filter",
                sort: "floors/sort",
                corpses: "corpses/list"
            }),
            actions: function () {
                return ['edit', 'delete'];
            }
        },
        async mounted() {
            if (this.corpses && this.corpses.length === 0)
                await this.loadCorpses();
        },
        methods: {
            ...mapActions({
                updateList: 'floors/index',
                updateSort: "floors/updateSort",
                updateFilter: "floors/updateFilter",
                updateColumn: "floors/updateColumn",
                updatePagination: "floors/updatePagination",
                editModel: 'floors/show',
                empty: 'floors/empty',
                delete: 'floors/destroy',
                refreshData: 'floors/refreshData',
                loadCorpses: "corpses/index",
            }),
            fetchData() {
                const query = {
                    ...this.filter,
                    ...this.pagination,
                    ...this.sort
                };
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },

            refresh() {
                this.refreshData()
                    .then(res => {
                        this.filterForm = JSON.parse(JSON.stringify(this.filter));
                    })
                    .catch(err => {

                    })
            },

            edit(model) {
                this.selectedModel = model;
                this.drawer.update.status = true;
            },
            async show(model) {
                await this.showModel(model.id)
                    .then(res => {
                        this.drawerShow = true;
                    })
                    .catch(err => {

                    })
            },
            destroy(model) {
                this.delete(model.id)
                    .then(res => {
                        this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                        this.fetchData()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            emptyModel() {
                this.empty()
            },
        }
    };
</script>